import React from "react"
import {
  BinaryTree,
  CrossPlatform,
  LockedBook,
  Scaling,
  Shield,
  UserPlus,
} from "../assets/icons"
import {
  useTranslation,
  Trans,
  Link,
  useI18next,
} from "gatsby-plugin-react-i18next"
import { graphql } from "gatsby"
import { GatsbySeo } from "gatsby-plugin-next-seo"

export default function Solutions() {
  const { languages, originalPath } = useI18next() // Get language info
  const { t, i18n } = useTranslation() // Get translation info

  const siteUrl = "https://arcanode.io/solutions/"

  const items = [
    {
      id: 1,
      icon: <Scaling className="w-12 h-12" />,
      text: t("solutions.list.0"),
    },
    {
      id: 2,
      icon: <UserPlus className="w-12 h-12" />,
      text: t("solutions.list.1"),
    },
    {
      id: 3,
      icon: <CrossPlatform className="w-12 h-12" />,
      text: t("solutions.list.2"),
    },
    {
      id: 4,
      icon: <Shield className="w-12 h-12" />,
      text: t("solutions.list.3"),
    },
    {
      id: 5,
      icon: <LockedBook className="w-12 h-12" />,
      text: t("solutions.list.4"),
    },
    {
      id: 6,
      icon: <BinaryTree className="w-12 h-12" />,
      text: t("solutions.list.5"),
    },
  ]

  return (
    <>
      <GatsbySeo
        title={t("solutions.seo.title", "Advantages")}
        description={t("solutions.seo.description", "See our advantages!")}
        canonical={`${siteUrl}/${i18n.language}${originalPath}`}
        openGraph={{
          url: `${siteUrl}/${i18n.language}${originalPath}`,
          title: t("solutions.seo.title", "Advantages"),
          description: t("solutions.seo.description", "See our advantages!"),
          images: [
            {
              url: `${siteUrl}/icons/icon-512x512.png`,
              width: 512,
              height: 512,
              alt: "Arcanode",
            },
          ],
          site_name: "Arcanode",
        }}
        twitter={{
          handle: "@arcanode",
          site: "@arcanode",
          cardType: "summary_large_image",
        }}
        languageAlternates={languages.map(lang => ({
          hrefLang: lang,
          href: `${siteUrl}/${lang}${originalPath}`,
        }))}
      />
      <div className="flex flex-col pt-6 text-center w-full">
        <div className="flex justify-center flex-col items-center">
          <p className="text-sm uppercase max-w-md">
            <Trans i18nKey="solutions.header">
              we lead the charge in transforming e-com, retail and content
              creation with our patented{" "}
              <strong>Open Metaverse Standard Protocol.</strong>
            </Trans>
          </p>

          <p className="text-sm uppercase max-w-md py-10 px-4">
            <Trans i18nKey="solutions.sub">
              With <strong>cutting-edge technologies</strong>, we{" "}
              <strong>reduce</strong> <strong>server</strong>{" "}
              <strong>costs</strong> and increase performance.
            </Trans>
          </p>
          <div className="pb-6">
            <Link
              to="/products"
              className="border hover:bg-gray-200 hover:text-gray-700 duration-500 text-white font-bold uppercase text-xs py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              <Trans className="text-xs" i18nKey="solutions.button">
                Scopri i nostri prodotti
              </Trans>
            </Link>
          </div>

          <div className="grid grid-cols-3 gap-2 gap-y-8 max-w-96 pt-10 px-2">
            {items.map(item => (
              <div
                key={item.id}
                className="flex flex-col text-center justify-center"
              >
                <div className="mx-auto mb-2">{item.icon}</div>
                <p
                  className="text-xs  uppercase"
                  dangerouslySetInnerHTML={{ __html: item.text }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
